const sagaEvents = {
  fetchMenu: 'fetchMenu',
  incrementSize: 'incrementSize',
  decrementSize: 'decrementSize',
  incrementQuantity: 'incrementQuantity',
  decrementQuantity: 'decrementQuantity',
  incrementFlavor: 'incrementFlavor',
  decrementFlavor: 'decrementFlavor',
  updateBag: 'updateBag',
  setMenu: 'setMenu',
  validateCart: 'validateCart',
  submit: 'submit',
};

const events = {
  ...Object.fromEntries(
    Object.entries(sagaEvents)
      .map(([name, value]) => ([name, `saga/${value}`]))
  )
};

export default events;
