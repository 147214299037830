import { useEffect, useState, useMemo } from 'react';
import Box from 'elements/Box';
import styled from 'styled-components';

const Page = styled(Box)(({ bg, theme }) => `
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  ${bg ? `
    background: url(${bg}) no-repeat center center fixed;
    background-size: cover;
    transition: height ${theme.speeds.slow};
  `: ''}
`);

export const PageWithBackground = ({ bg, ...rest }) => {
  const img = useMemo(() => new Image(), []);
  const [isReady, setReady] = useState(false);
  const props = {
    bg: isReady ? bg : undefined,
    ...rest
  };
  useEffect(() => {
    img.onload = () => setReady(true);
    img.src = bg;
  }, [bg, img, setReady]);
  return <Page {...props} />;
};

export const Content = styled(Box)`
  padding: 12rem 2rem 2rem;
  width: 100%;
  max-width: 45rem;
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default Page;
