import IframeResizer from 'iframe-resizer-react'
import styled from 'styled-components';
import Box from 'elements/Box';
import { Paragraph, ExternalLink } from 'elements/Text';
import { CardContainer } from 'containers/Card';
const Heading = styled.h2`
  text-align: center;
`;
const SignUp = () => {
  return (
    <CardContainer p="3.5rem 3rem 2.5rem" maxWidth="35rem" margin="0 auto">
      <Box style={{
        minHeight: '12rem', background: 'url(/spinner.gif) center center no-repeat'
      }}>
        <IframeResizer
          style={{
            width:'100%',
            height: 0,
            transition: 'height 0.5s',
          }}
          title="sign-up"
          data-w-type="embedded"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          heightCalculationMethod="min"
          src="https://0uopj.mjt.lu/wgt/0uopj/1i2/form?c=986e2e82"
        >
      </IframeResizer>
      </Box>
    </CardContainer>
  );
};
const Subscribed = () => (
  <CardContainer p="3rem" maxWidth="30rem" margin="0 auto">
    <Heading>Thanks for Subscribing!</Heading>
    <Paragraph textAlign="center">
      Keep your eyes peeled for delicious updates.
    </Paragraph>
    <Paragraph textAlign="center">
      And follow us on <ExternalLink target="_blank" href="https://instagram.com/thedandybambi">Instagram</ExternalLink> to satisfy your carb fix.
    </Paragraph>
  </CardContainer>
)
export {
  SignUp as default,
  Subscribed,
};
