import styled from 'styled-components'

const Header = styled.header(({ theme, shrunk }) => `
  position: fixed;
  width: 100%;
  z-index: 10;
  background: ${theme.colors.blues.ghost};
  box-shadow: 0 2px 4px 0 ${theme.colors.utility.primary}40;
  padding-bottom: 0.25rem;

  svg {
    position: fixed;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    height: 10rem;
    z-index: 100;
    transition: all 0.5s;
  }
  nav {
    background: ${theme.colors.white};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin-top: 4.125rem;
    outline: ${theme.strokes.medium} solid ${theme.colors.utility.primary};
    outline-offset: 0.25rem;
    border-bottom: ${theme.strokes.small} solid ${theme.colors.utility.primary};
    border-top: ${theme.strokes.small} solid ${theme.colors.utility.primary};
    transition: all 0.5s;
  }
  nav:after {
    content: '';
    position: absolute;
    top: 0.25rem;
    bottom: 0.25rem;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.utility.primary};
    border-top: 1px solid ${theme.colors.utility.primary};
    z-index: -1;
  }
  nav span {
    transition: all 0.5s;
    padding: 1.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: ${theme.colors.utility.primary};
  }
  nav a {
    transition: all 0.5s;
    user-select: none;
    text-decoration: none;
    font-family: ${theme.fontFamilies.heading};
    color: ${theme.colors.utility.primary};
  }
  nav ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    height: 0;
    transition: all 0.5s;
    overflow: hidden;
    color: ${theme.colors.utility.primary};
  }
  nav ul.show {
    height: 10rem;
  }
  nav ul li {
    text-align: center;
    font-size: 1.75rem;
    line-height: 3rem;
    transition: all 0.5s;
  }
  nav ul li:first-of-type {
    padding-top: 3rem;
  }
  ${shrunk ? `
    nav ul.show {
      height: 7rem;
    }
    nav ul li:first-of-type {
      padding-top: 2rem;
    }
    nav ul li {
      line-height: 2.25rem;
    }
    svg {
      top: 0.25rem;
      height: 6rem;
    }
    nav {
      margin-top: 2.125rem;
    }
    nav span {
      padding: 0.375rem 0.5rem;
    }
    nav a {
      font-size: 1.25rem;
    }
    nav:before {
      border-color: transparent;
    }
  ` : ''}
`);

export default Header;
