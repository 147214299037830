import FooterContainer, { FooterRow } from 'containers/Footer';
import Copyright from 'components/Copyright';
import { Link } from 'react-router-dom';
import { paths } from 'routes';

const Footer = () => (
  <FooterContainer>
    <Copyright />
    <FooterRow>
      <Link to={paths.privacy}>Privacy Policy</Link>
      <Link to={paths.cookies}>Cookie Policy</Link>
    </FooterRow>
  </FooterContainer>
);

export default Footer;
