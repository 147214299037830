import React from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import App from './App';
import HomePage from './routes/HomePage';
import ContactPage from './routes/ContactPage';
import PoliciesPage from './routes/PoliciesPage';
import NotFound from './routes/NotFound';
import SubscribedPage from './routes/SubscribedPage';
import routes, { paths } from 'routes';
import { CardContainer } from 'containers/Card';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path={`${routes.root}*`} element={<App />}>
        <Route path={routes.subscribed} element={<SubscribedPage />} />
        <Route path={routes.contact} element={<ContactPage />} />
        <Route path={routes.policies.root} element={<PoliciesPage />}>
          <Route path={routes.policies.cookies} element={
            <iframe title="Cookie Policy" src="/cookies.html" style={{ background: 'white', flex: 1 }} />
          } />
          <Route path={routes.policies.privacy} element={
            <iframe title="Privacy Policy" src="/privacy.html" style={{ background: 'white', flex: 1 }} />
          } />
          <Route index element={
            <CardContainer p="3rem" display="flex" gridGap="1rem" alignItems="center">
              <Link to={paths.privacy}>Privacy Policy</Link>
              <Link to={paths.cookies}>Cookie Policy</Link>
            </CardContainer>
          } />
        </Route>
        <Route path="*" element={<NotFound />} />
        <Route index element={<HomePage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
