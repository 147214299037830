export const initialState = () => ({
  addOns: {},
  order: {
    menu: '',
    pickup: {
      date: '',
      target: '',
      deadline: '',
    },
    menus: {},
    subtotal: 0,
    taxPercent: 0.07,
    tax: 0,
    total: 0,
  },
  categories: {},
  flavors: {},
  menus: {
    loading: true,
  },
  posted: {},
  products: {},
  quantities: {},
  sizes: {},
});

export const initialOrderDetails = () => ({
  counts: {},
  errors: {},
  prices: {},
  products: {},
  subtotal: 0,
});

export const initialProductCounts = () => ({
  sizes: {},
  quantities: {},
  flavors: {},
});
