import styled from 'styled-components';
import { Link as ReactLink } from 'react-router-dom';
import { space, color, typography } from 'styled-system';

export const Button = styled.button(({ theme }) => `
  cursor: pointer;
  border: none;
  background: none;
  display: inline;
  padding: 0;
  margin: 0;
  color: ${theme.colors.utility.primary};
`);
export const Link = styled(ReactLink)(({ theme }) => `
  color: ${theme.colors.utility.primary};
`);
export const ExternalLink = styled.a(({ theme }) => `
  color: ${theme.colors.utility.primary};
`);
export const Span = styled.span(({ nowrap, clickable }) => `
  ${nowrap ? 'white-space: nowrap;' : ''}
  ${clickable ? 'cursor: pointer;' : ''}
`, space, color, typography);
export const Paragraph = styled.p(() => `
  line-height: 1.25rem;
`, space, color, typography);
export const Strong = styled.strong(({ nowrap }) => `
  ${nowrap ? 'white-space: nowrap;' : ''}
`, space, color, typography);
export const Small = styled.small(space, color, typography);
export const Icon = styled.span(({ theme }) => `
  svg {
    height: 1rem;
    display: inline;
    stroke: ${theme.colors.dark};
    fill: ${theme.colors.dark};
  }
`);
export const Heading1 = styled('h1')(({ theme }) => `
  font-family: ${theme.fontFamilies.heading};
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: normal;
  text-align: center;
  color: ${theme.colors.utility.primary};
  border-top: ${theme.strokes.medium} solid ${theme.colors.utility.primary};
  border-bottom: ${theme.strokes.medium} solid ${theme.colors.utility.primary};
  margin: 4rem 0 1rem;
`);

export const Heading2 = styled('h2')(({ theme }) =>`
  font-family: ${theme.fontFamilies.heading};
  position: relative;
  overflow: hidden;
  color: ${theme.colors.utility.primary};
  text-align: center;
  font-weight: normal;
  font-size: 2rem;
  transition:
    margin ${theme.speeds.slow},
    max-height ${theme.speeds.slow},
    opacity ${theme.speeds.fast};
  &:before, &:after {
    position: absolute;
    top: 50%;
    overflow: hidden;
    width: 50%;
    content: '';
    background-color: ${theme.colors.utility.primary};
    height: ${theme.strokes.medium};
    transition:
      max-height ${theme.speeds.moderate},
      opacity ${theme.speeds.fast};
  }
  &:before {
    margin-left: -50%;
    text-align: right;
  }
`, space, color, typography);
