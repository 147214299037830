import Footer from 'components/Footer';
import { Subscribed } from 'components/SignUp';
import Page, { Content } from 'containers/Page';

const SubscribedPage = () => {
  return (
    <Page bg="https://i.imgur.com/nnRt4e7.jpg">
      <Content p="2rem">
        <Subscribed />
      </Content>
      <Footer />
    </Page>
  );
}

export default SubscribedPage;
