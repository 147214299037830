import { Outlet } from 'react-router-dom'
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux'
import { lightTheme } from './theme';
import store from './store';

import Header from 'components/Header';

const App = ({ children }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <Header />
        <Outlet />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
