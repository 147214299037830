import { Span, Link } from 'elements/Text';

const Copyright = () => {
  return (
    <Span>
      Site &copy;2024 <Link to="/">The Dandy Bambi LLC</Link>. All rights reserved.
    </Span>
  );
}

export default Copyright;
