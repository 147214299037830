const productErrors = {
  missingQuantity: 'missingQuantity',
  tooManyFlavors: 'tooManyFlavors'
}

const errors = {
  product: {
    ...Object.fromEntries(Object.entries(productErrors)
      .map(([key, value]) => ([key, `product/${value}`])))
  }
}

export default errors;
