import styled from 'styled-components';
import { space, flexbox, layout } from 'styled-system';
import Box from 'elements/Box';
import withErrorHandling from 'enhancers/withErrorHandling';

export const CardHeader = styled('h4')`
  margin: 0;
  ${space}
  ${layout}
  ${flexbox}
`;
export const CardContainer = withErrorHandling(styled(Box)(({ isSelected, hide, merge, theme }) => `
  width: 100%;
  background-image: linear-gradient(to top,
    ${theme.colors.utility.primary} calc(50% + ${theme.strokes.medium}),
    ${theme.colors.white} calc(50% - ${theme.strokes.medium})
  );
  background-size: 100% 200%;
  background-position: top left;
  transition:
    max-height ${theme.speeds.moderate},
    box-shadow ${theme.speeds.moderate},
    opacity ${theme.speeds.fast},
    margin ${theme.speeds.fast},
    transform ${theme.speeds.ultrafast},
    color ${theme.speeds.fast},
    background-position ${theme.speeds.fast};
  box-shadow: 0px 4px 8px 2px ${theme.colors.utility.primary}40;
  margin-bottom: 2rem;
  user-select: none;
  overflow: hidden;
  &:active {
    transform: scale(0.99);
    box-shadow: 0 2px 4px 0 ${theme.colors.utility.primary}40;
  }
  ${isSelected
    ? `background-position: bottom left;
       color: ${theme.colors.white};`
    : ''
  }
  ${hide
    ? `
      max-height: 0;
      box-shadow: none;
      margin: 0;
      opacity: 0;
    ` : `
      max-height: 80rem;
      opacity: 1;
  `}
  ${merge && `
    margin-top: 0;
    box-shadow: none;
    background: ${theme.colors.white};
    &:active {
      transform: none;
      box-shadow: none;
    }
  `}
`));
