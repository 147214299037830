import Footer from 'components/Footer';
import Page, { Content } from 'containers/Page';
import { Outlet } from 'react-router-dom';

const PoliciesPage = () => {
  return (
    <Page>
      <Content p="1rem" mb="1rem">
        <Outlet />
      </Content>
      <Footer />
    </Page>
  );
}

export default PoliciesPage;
