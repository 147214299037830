import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { CardContainer } from 'containers/Card';
import Box from 'elements/Box';
import { Paragraph } from 'elements/Text';
import { Button } from 'elements/Input';
import { paths } from 'routes';

const Header = styled.h2`
  text-align: center;
`;
const BigParagraph = styled(Paragraph)`
  text-align: center;
  line-height: 1.75rem;
  font-size: 1.25rem;
`;
const Lost = () => {
  const navigate = useNavigate();
  return (
    <CardContainer>
      <Box m="3rem">
        <Header>You Look Lost</Header>
        <BigParagraph>
          This page doesn't exist.
        </BigParagraph>
        <Box display="flex" justifyContent="center">
          <Button
            primary
            type="button"
            onClick={() => navigate(paths.home)}
          >
            Go Home
          </Button>
        </Box>
      </Box>
    </CardContainer>
  );
}
export default Lost;
