import React from 'react';
import { ThemeProvider } from 'styled-components';
import { errorTheme } from 'theme';

const withErrorHandling = WrappedComponent =>
  React.forwardRef(({ hasError, ...restProps }, ref) => (
    <ThemeProvider theme={hasError
      ? errorTheme
      : theme => theme
    }>
      <WrappedComponent ref={ref} {...restProps} />
    </ThemeProvider>
  ));

export default withErrorHandling;
