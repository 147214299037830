import styled from 'styled-components';
import { space, layout, flexbox, grid } from 'styled-system';
import withErrorHandling from 'enhancers/withErrorHandling';

const Box = withErrorHandling(styled.div`
  ${space}
  ${layout}
  ${flexbox}
  ${grid}
`);

export default Box;
