import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import events from 'store/events';
import { orderSelector } from 'store/selectors';
import {
  updateMenu, setMenu, resetBag,
  incrementSize, decrementSize,
  incrementQuantity, decrementQuantity,
  incrementFlavor, decrementFlavor,
  updateProductPrice, updateMenuSubtotal, updateCartPrice,
  updateProductCount,
  validateProductInCart,
  simplifyQuantities,
} from 'store/reducer';
import { getMenu, submit } from 'api';

const updateBag = payload => ({ ...payload, type: events.updateBag });

function* fetchMenu() {
  const menu = yield call(getMenu);
  yield put(updateMenu(menu));
  yield put(resetBag());
}

function* handleSetMenu(payload) {
  yield put(setMenu(payload));
  yield put(updateCartPrice(payload));
}

function* handleSubmit(payload) {
  const order = yield select(orderSelector);
  const { status } = yield call(submit, {
    ...order.menus[order.menu],
    ...payload.details,
    subtotal: order.subtotal,
    tax: order.tax,
    total: order.total,
  });
  if(status === 'success') {
    // todo: set error messages
    if (payload.onSuccess) yield call(payload.onSuccess);
  } else {
    // todo: clear error messages
    if (payload.onFailure) yield call(payload.onFailure);
  }
}

function* validateCart(payload) {
  const order = yield select(orderSelector);
  for (let productId in order.menus[order.menu].products) {
    yield put(validateProductInCart({
      menuId: order.menu, productId
    }));
  }
  const validatedOrder = yield select(orderSelector);
  if (Object.keys(
    validatedOrder.menus[validatedOrder.menu].errors
  ).length) {
    // todo: set error messages
    if (payload.onFailure) yield call(payload.onFailure);
  } else {
    // todo: clear error messages
    if (payload.onSuccess) yield call(payload.onSuccess);
  }
}

function* onUpdateBag(payload) {
  yield put(updateProductCount(payload));
  yield put(updateProductPrice(payload));
  yield put(updateMenuSubtotal(payload));
  yield put(updateCartPrice(payload));
  yield put(validateProductInCart(payload));
}

function* onIncrementSize(payload) {
  yield put(incrementSize(payload));
  yield put(updateBag(payload));
}

function* onDecrementSize(payload) {
  yield put(decrementSize(payload));
  yield put(updateBag(payload));
}

function* onIncrementQuantity(payload) {
  yield put(incrementQuantity(payload));
  yield put(updateProductCount(payload));
  yield put(simplifyQuantities(payload));
  yield put(updateBag(payload));
}

function* onDecrementQuantity(payload) {
  yield put(decrementQuantity(payload));
  yield put(updateProductCount(payload));
  yield put(simplifyQuantities(payload));
  yield put(updateBag(payload));
}

function* onIncrementFlavor(payload) {
  yield put(incrementFlavor(payload));
  yield put(validateProductInCart(payload));
}

function* onDecrementFlavor(payload) {
  yield put(decrementFlavor(payload));
  yield put(validateProductInCart(payload));
}

export default function* sagas() {
  yield all([
    takeEvery(events.fetchMenu, fetchMenu),
    takeEvery(events.setMenu, handleSetMenu),
    takeEvery(events.validateCart, validateCart),
    takeEvery(events.submit, handleSubmit),
    takeEvery(events.incrementSize, onIncrementSize),
    takeEvery(events.decrementSize, onDecrementSize),
    takeEvery(events.incrementQuantity, onIncrementQuantity),
    takeEvery(events.decrementQuantity, onDecrementQuantity),
    takeEvery(events.incrementFlavor, onIncrementFlavor),
    takeEvery(events.decrementFlavor, onDecrementFlavor),
    takeEvery(events.updateBag, onUpdateBag),
  ]);
}
