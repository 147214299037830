import { createSlice } from '@reduxjs/toolkit'
import codes from 'store/errors';
import {
  initialState, initialOrderDetails, initialProductCounts
} from 'store/initializer';
import cateringConstants from 'constants/catering';
import menuConstants from 'constants/menu';

const { product: productErrors } = codes;

export const slice = createSlice({
  name: 'reducer',
  initialState: initialState(),
  reducers: {
    updateMenu: (state, { payload: {
      addOns, categories, flavors, menus, posted,
      products,
      quantities, sizes,
    }}) => ({
      ...state,
      addOns, categories, flavors, menus, posted,
      products: Object.fromEntries(
        Object.entries(products).map(([productId, product]) => ([productId, {
            ...product,
            sortedQuantities: product.quantities
              ? Object.keys(product.quantities).sort(
                  (a, b) => quantities[b].units - quantities[a].units
                )
              : undefined
          }]))
      ),
      quantities, sizes,
    }),
    setMenu: (state, { payload: { menuId }}) => {
      state.order.menu = menuId;
      if (menuId === cateringConstants.menuId || !menuId.length) {
        state.order.pickup = initialState().order.pickup;
      } else {
        state.order.pickup = {
          date: menuId,
          target: menuConstants.target,
          deadline: menuConstants.deadline,
        }
      }
    },
    setPickup: (state, { payload: { pickup }}) => {
      state.order.pickup = {
        ...state.order.pickup,
        ...pickup,
      };
    },
    resetBag: state => {
      state.order = {
        ...initialState().order,
        menus: Object.fromEntries(Object.keys(state.posted).map(menuId => [
          menuId, { ...initialOrderDetails() }
        ])),
      };
    },
    toggleItemInCart: ({ order }, { payload: { productId, menuId }}) => {
      if (order.menus[menuId].products[productId]) {
        delete order.menus[menuId].products[productId];
        delete order.menus[menuId].errors[productId];
      } else {
        order.menus[menuId].products[productId] = true;
      }
    },
    incrementSize: ({ order }, { payload: { productId, sizeId, menuId }}) => {
      const counts = order.menus[menuId].counts[productId] || {
        ...initialProductCounts()
      };
      if (!counts.sizes[sizeId]) {
        counts.sizes[sizeId] = 1;
      } else {
        counts.sizes[sizeId]++;
      }
      order.menus[menuId].counts[productId] = counts;
    },
    decrementSize: ({ order }, { payload: { productId, sizeId, menuId }}) => {
      order.menus[menuId].counts[productId].sizes[sizeId]--;
    },
    incrementQuantity: ({ order }, { payload: { productId, quantityId, menuId }}) => {
      if (!order.menus[menuId].counts[productId]) {
        order.menus[menuId].counts[productId] = {
          ...initialProductCounts()
        };
      }
      if (!order.menus[menuId].counts[productId].quantities[quantityId]) {
        order.menus[menuId].counts[productId].quantities[quantityId] = 1;
      } else {
        order.menus[menuId].counts[productId].quantities[quantityId]++;
      }
    },
    decrementQuantity: ({ products, quantities, order }, { payload: { productId, quantityId, menuId }}) => {
      let count = order.menus[menuId].counts[productId].quantities[quantityId] - 1;
      products[productId].sortedQuantities.forEach(quantityId => {
        if ((
          order.menus[menuId].counts[productId].total -
          order.menus[menuId].counts[productId].quantities[quantityId]
        ) % quantities[quantityId].replaces === 0) count--;
      });
      order.menus[menuId].counts[productId].quantities[quantityId] = count;
    },
    simplifyQuantities: ({ products, order, quantities }, { payload: { productId, menuId }}) => {
      const counts = Object.fromEntries(Object.keys(products[productId].quantities)
        .map(quantityId => ([quantityId, 0]))
      );
      let remainder = order.menus[menuId].counts[productId].total;
      const { sortedQuantities } = products[productId];
      sortedQuantities.forEach(quantityId => {
        while (remainder >= quantities[quantityId].units || (
          quantities[quantityId].replaces && remainder >= quantities[quantityId].replaces
        )) {
          counts[quantityId]++;
          remainder-=quantities[quantityId].units;
        }
      })
      order.menus[menuId].counts[productId].quantities = counts;
    },
    incrementFlavor: ({ order }, { payload: { productId, menuId, flavorId } }) => {
      if (!order.menus[menuId].counts[productId].flavors[flavorId]) {
        order.menus[menuId].counts[productId].flavors[flavorId] = 1;
      } else {
        order.menus[menuId].counts[productId].flavors[flavorId]++;
      }
    },
    decrementFlavor: ({ order }, { payload: { productId, menuId, flavorId }}) => {
      order.menus[menuId].counts[productId].flavors[flavorId]--;
    },
    updateProductCount: ({ order, products, quantities, sizes }, { payload: { productId, menuId }}) => {
      const counts = order.menus[menuId].counts[productId];
      counts.total = 0;
      for (let sizeId in counts.sizes) {
        counts.total += sizes[sizeId].units * counts.sizes[sizeId];
      }
      for (let quantityId in counts.quantities) {
        counts.total += quantities[quantityId].units * counts.quantities[quantityId];
      }
      order.menus[menuId].counts[productId] = counts;
    },
    updateProductPrice: ({ products, order }, { payload: { productId, menuId }}) => {
      let price = 0;
      const counts = order.menus[menuId].counts[productId];
      for (let sizeId in counts.sizes) {
        price += products[productId].sizes[sizeId].price * counts.sizes[sizeId];
      }
      for (let quantityId in counts.quantities) {
        price += products[productId].quantities[quantityId].price * counts.quantities[quantityId];
      }
      order.menus[menuId].prices[productId] = price;
    },
    updateMenuSubtotal: ({ order }, { payload: { menuId }}) => {
      let subtotal = 0;
      for (let productId in order.menus[menuId].products) {
        subtotal += order.menus[menuId].prices[productId] || 0;
      }
      order.menus[menuId].subtotal = subtotal;
    },
    updateCartPrice: ({ menus, order, products }, { payload: { menuId }}) => {
      if (!order.menu.length || menus.loading) {
        order.subtotal = 0;
        order.tax = 0;
        order.total = 0;
        return;
      }
      order.subtotal = order.menus[order.menu].subtotal || 0;
      order.tax = Number.parseFloat(
        Number.parseFloat(order.taxPercent * order.subtotal).toFixed(2)
      );
      order.total = order.subtotal + order.tax;
    },
    validateProductInCart: ({ order, sizes, quantities }, { payload: { productId, menuId }}) => {
      const errors = {};
      const counts = order.menus[menuId].counts[productId] || initialProductCounts();
      const totalSizes = Object.entries(counts.sizes)
        .reduce((sum, [sizeId, count]) => sum + sizes[sizeId].units * count, 0);
      const totalQuantities = Object.entries(counts.quantities)
        .reduce((sum, [quantityId, count]) => sum + quantities[quantityId].units * count, 0);
      const totalFlavors = Object.entries(counts.flavors)
        .reduce((sum, [flavorId, count]) => sum + count, 0);
      delete order.menus[menuId].errors[productId];
      if (totalSizes + totalQuantities <= 0)
        errors[productErrors.missingQuantity] = true;
      else if (totalSizes + totalQuantities < totalFlavors)
        errors[productErrors.tooManyFlavors] = true;
      if (Object.keys(errors).length)
        order.menus[menuId].errors[productId] = errors;
    }
  }
})

export const {
  updateMenu,
  setMenu,
  setPickup,
  resetBag,
  toggleItemInCart,
  incrementSize,
  decrementSize,
  incrementQuantity,
  decrementQuantity,
  simplifyQuantities,
  incrementFlavor,
  decrementFlavor,
  updateProductPrice,
  updateMenuSubtotal,
  updateCartPrice,
  updateProductCount,
  validateProductInCart,
} = slice.actions

export default slice.reducer
