import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {ReactComponent as ReactLogo} from 'assets/logo.svg';
import HeaderContainer from 'containers/Header';
import { paths } from 'routes';

const Header = () => {
  const [isShrunk, setIsShrunk] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => setIsNavOpen(!isNavOpen);
  const onScroll = () => {
    if (document.documentElement.scrollTop > 30) {
      setIsShrunk(true);
    } else {
      setIsShrunk(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);
    onScroll();
  }, [])

  return (
    <HeaderContainer shrunk={isShrunk}>
      <nav onClick={toggleNav}>
        <span>{isNavOpen ? '\u2715':'\u2630'}</span>
        <ul className={isNavOpen ? 'show': undefined}>
          <li><Link to={paths.contact}>{'\u2013 Keep in Touch \u2013'}</Link></li>
          <li>
            <a href="https://instagram.com/thedandybambi" target="_blank" rel="noreferrer">
              {'\u2013 Instagram \u2013'}
            </a>
          </li>
        </ul>
      </nav>
      <Link to="/" style={{display: 'block'}} onClick={() => isNavOpen && toggleNav()}><ReactLogo /></Link>
    </HeaderContainer>
  );
}

export default Header;
