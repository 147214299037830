import Footer from 'components/Footer';
import Lost from 'components/Lost';
import { PageWithBackground, Content } from 'containers/Page';

const NotFoundPage = () => {
  return (
    <PageWithBackground bg="https://i.imgur.com/srDmCQH.jpg">
      <Content p="2rem">
        <Lost />
      </Content>
      <Footer />
    </PageWithBackground>
  );
}

export default NotFoundPage;
