import { useNavigate } from 'react-router-dom';
import { CardContainer } from 'containers/Card';
import Box from 'elements/Box';
import { Paragraph } from 'elements/Text';
import { Button } from 'elements/Input';
import { paths } from 'routes';

const About = () => {
  const navigate = useNavigate();
  return (
    <CardContainer maxWidth="35rem" margin="0 auto">
      <Box m="4rem 3rem 3rem">
        <Paragraph>
          The Dandy Bambi is an artisanal sourdough microbakery operating out of our home kitchen in Pittsburgh's East End.
        </Paragraph>
        <Paragraph>
          We specialize in naturally leavened sourdough breads, including rustic loaves, crusty baguettes, hand-rolled bagels, super-soft cinnamon rolls, and tender milk buns.
        </Paragraph>
        <Paragraph>
          We also offer lye-dipped soft pretzels, savory sourdough crackers, garlicky croutons, wholesome granolas, cookies of all flavors, and more.
        </Paragraph>
        <Paragraph>
          Our ingredients are 100% vegetarian and all-natural; sourced locally when possible.
        </Paragraph>
        <Box display="flex" justifyContent="center" pt="1rem">
          <Button
            primary
            type="button"
            onClick={() => navigate(paths.contact)}
          >
            Get Updates
          </Button>
        </Box>
      </Box>
    </CardContainer>
  );
}
export default About;
