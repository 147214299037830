import styled from 'styled-components';
import { CardContainer } from 'containers/Card';
import Box from 'elements/Box';
import { Paragraph, ExternalLink } from 'elements/Text';
const BigParagraph = styled(Paragraph)`
  line-height: 1.5rem;
  font-size: 1.25rem;
`;
const Contact = () => (
  <CardContainer maxWidth="35rem" margin="0 auto">
    <Box m="3rem">
      <BigParagraph>
        Instagram: <ExternalLink target="_blank" href="https://instagram.com/thedandybambi">@thedandybambi</ExternalLink>
      </BigParagraph>
      <BigParagraph>
        Inquiries: <ExternalLink target="_blank" href="mailto:info@thedandybambi.com">info@thedandybambi.com</ExternalLink>
      </BigParagraph>
      <BigParagraph>
        Issues: <ExternalLink target="_blank" href="mailto:help@thedandybambi.com">help@thedandybambi.com</ExternalLink>
      </BigParagraph>
    </Box>
  </CardContainer>
);

export default Contact;
