import About from 'components/About';
import Footer from 'components/Footer';
import Page, { Content } from 'containers/Page';

const HomePage = () => {
  return (
    <Page bg="https://i.imgur.com/w7i6t56.jpg">
      <Content p="2rem">
        <About />
      </Content>
      <Footer />
    </Page>
  );
}

export default HomePage;
