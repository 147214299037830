const constants = {
  menuId: 'catering',
  minDaysOut: 14,
  maxDaysOut: 91,
  pickupTimeInterval: 30,
  minTarget: '08:00',
  maxTarget: '20:00',
}

export default constants;
