import Footer from 'components/Footer';
import SignUp from 'components/SignUp';
import Contact from 'components/Contact';
import Page, { Content } from 'containers/Page';

const ContactPage = () => {
  return (
    <Page bg="https://i.imgur.com/7jSpD4G.jpg">
      <Content p="2rem">
        <Contact />
        <SignUp />
      </Content>
      <Footer />
    </Page>
  );
}

export default ContactPage;
