import styled from 'styled-components';
import withErrorHandling from 'enhancers/withErrorHandling';
import { space } from 'styled-system';

export const Button = styled.button(({ theme, primary, bounce }) => `
  border: ${theme.strokes.medium} solid ${theme.colors.utility.primary};
  border-radius: 0;
  line-height: 3rem;
  padding: 0 1rem;
  font-size: 15px;
  transition:
    transform ${theme.speeds.fast},
    color ${theme.speeds.fast},
    background-color ${theme.speeds.fast},
    border ${theme.speeds.fast};
  color: ${theme.colors.utility.primary};
  background: ${theme.colors.white};
  ${bounce ? `
    &:active {
        transform: scale(0.95);
    }
  ` : ''}
  ${primary ? `
    color: ${theme.colors.white};
    background: ${theme.colors.utility.primary};
    &:disabled {
      border-color: ${theme.colors.utility.secondary};
      color: ${theme.colors.utility.ultralight};
      background: ${theme.colors.utility.secondary};
      cursor: not-allowed;
    }
  ` : ''}
`);

export const TextButton = styled.button(({ theme }) => `
  display: inline;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background: none;
  color: ${theme.colors.utility.secondary};
  text-decoration: underline;
  cursor: pointer;
`);

export const Input = withErrorHandling(styled.input(({ theme, fluid }) => `
  width: ${fluid ? '100%' : 'unset'};
  margin-bottom: 1rem;
  line-height: 3rem;
  background: none;
  border: none;
  padding: 0 1rem;
  border-bottom: ${theme.strokes.medium} solid ${theme.colors.utility.primary};
  border-radius: 0;
  outline: none;
  &:focus {
    background: ${theme.colors.utility.ultralight};
  }
  ::placeholder {
    color: ${theme.colors.utility.secondary};
  }
`, space));

export const TextArea = styled.textarea(({ theme, fluid }) => `
  width: ${fluid ? '100%' : 'unset'};
  margin-bottom: 1rem;
  line-height: 2rem;
  background: none;
  border: none;
  padding: 0.5rem 1rem 1rem;
  border-bottom: ${theme.strokes.medium} solid ${theme.colors.utility.primary};
  border-radius: 0;
  outline: none;
  resize: none;
  font-family: ${theme.fontFamilies.body};
  &:focus {
    background: ${theme.colors.utility.ultralight};
  }
  ::placeholder {
    color: ${theme.colors.utility.secondary};
  }
`);
