const routes = {
  root: '/',
  contact: 'contact-us/',
  policies: {
    root: 'policies/',
    privacy: 'privacy/',
    cookies: 'cookies/',
  },
  receipt: {
    root: 'receipt/',
    order: ':orderId',
  },
  subscribed: 'subscribed/',
  catering: 'super-secret-catering-menu/',
  menu: {
    root: 'super-secret-menu/',
    order: {
      root: ':menuId/',
      catering: 'catering/',
      quantity: 'quantity/',
      confirm: 'confirm/',
      receipt: 'receipt/', // todo: deprecate
    }
  },
}
const paths = {
  home:           routes.root,
  contact:        `${routes.root}${routes.contact}`,
  policies:       `${routes.root}${routes.policies.root}`,
  privacy:        `${routes.root}${routes.policies.root}${routes.policies.privacy}`,
  cookies:        `${routes.root}${routes.policies.root}${routes.policies.cookies}`,
  subscribed:     `${routes.root}${routes.subscribed}`,
  staticCatering: `${routes.root}${routes.catering}`,
  menu:           `${routes.root}${routes.menu.root}`,
  catering:       `${routes.root}${routes.menu.root}${routes.menu.order.catering}`,
  order:          `${routes.root}${routes.menu.root}${routes.menu.order.root}`,
  quantity:       `${routes.root}${routes.menu.root}${routes.menu.order.root}${routes.menu.order.quantity}`,
  confirm:        `${routes.root}${routes.menu.root}${routes.menu.order.root}${routes.menu.order.confirm}`,
  receipt:        `${routes.root}${routes.menu.root}${routes.menu.order.root}${routes.menu.order.receipt}`,
}
export {
  routes as default,
  paths
};
