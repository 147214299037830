// https://coolors.co/1a0099-8aa6d3-c5d3e9-e2e9f4-f1f4fa-fcf0f0-f8e0e0-f0c0c0-e08080-c10000
export const lightTheme = () => {
  const speeds = {
    ultrafast: '0.125s',
    fast: '0.25s',
    moderate: '0.33s',
    slow: '1s'
  }
  const strokes = {
    small: '1px',
    medium: '2px'
  }
  const colors = {
    dark: '#070027',
    white: '#fefefe',
    reds: {
      primary: '#C10000',
      secondary: '#E08080',
      light: '#F0C0C0',
      ultralight: '#F8E0E0',
      ghost: '#FCF0F0'
    },
    blues: {
      primary: '#1a0099',
      secondary: '#8AA6D3',
      light: '#C5D3E9',
      ultralight: '#E2E9F4',
      ghost: '#f8fafd'
    },
    grays: {
      light: '#808080',
    },
  };
  colors.utility = colors.blues;
  const fontSizes = {
    body: '1rem',
    big: '1.25rem'
  };
  const fontFamilies = {
    body: 'Arial, sans-serif',
    heading: 'Garamond, "Times New Roman", Times, serif'
  };
  const fontWeights = {
    normal: 400,
    bold: 600
  }

  return {
    speeds,
    strokes,
    colors,
    fontSizes,
    fontFamilies,
    fontWeights,
  };
}

const lightThemeBase = lightTheme();

export const darkTheme = () => ({
  ...lightThemeBase,
  colors: {
    ...lightThemeBase.colors
    // todo: remap colors
  }
});

export const errorTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    utility: theme.colors.reds
  }
});
