import styled from 'styled-components';
import Box from 'elements/Box';

const FooterRow = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 1rem;
  justify-content: center;
`;

const Footer = styled(Box).attrs(props => ({
  as: 'footer', ...props
}))(({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  flex-shrink: 0;
  border-top: ${theme.strokes.medium} solid ${theme.colors.utility.primary};
  background: ${theme.colors.blues.ghost};
`);

export {
  Footer as default,
  FooterRow,
};
